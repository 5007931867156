import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { SText } from './creator-section-2';
import { WhiteButton } from '../../main/PC/main-section-4';
import mockup from '../../../assets/images/product/mockup-creator-last.png';
import largerMockup from '../../../assets/images/product/mockup-creator-last-larger.png';

import { CREATOR_FORM } from '../../../constants/urls';
import chicken from '../../../assets/images/illust/chicken.png';
import japan from '../../../assets/images/illust/japan.png';
import china from '../../../assets/images/illust/china.png';
import visitsix from '../../../assets/images/illust/visitsix.png';
import visitfive from '../../../assets/images/illust/visitfive.png';
import cake from '../../../assets/images/illust/cake.png';

export function CreatorSection4() {
  const isLargerPC = useMediaQuery({ query: '(min-width: 850px)' });
  return (
    <Wrapper>
      <Container>
        <Text>
          우리 동네 맛집을 공유하고
          <br />
          <Strong>나만의 맛집 매거진</Strong>
          을 만들어보세요
        </Text>
        <a
          href={CREATOR_FORM}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            position: 'relative', zIndex: '999', display: 'flex', justifyContent: 'center', textDecoration: 'none',
          }}
        >
          <SWhiteButton focus={false}>크리에이터 신청하기</SWhiteButton>
        </a>
        {isLargerPC ? <Mockup src={largerMockup} alt="mockup" /> : <Mockup src={mockup} alt="mockup" />}

        <Meat src={visitsix} />
        <Chicken src={chicken} />
        <China src={china} />
        <Cake src={cake} />
        <Gimbap src={visitfive} />
        <Japan src={japan} />
      </Container>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 140px;

  @media screen and (min-height: 850px) {
    padding-top: 170px;
  }

  align-items: center;
  overflow: hidden;
  background-color: var(--color-black-text);
`;

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const Text = styled(SText)`
  color: var(--color-darker-border);
  margin: 0;
  margin-bottom: 28px;

`;
const Strong = styled.strong`
  color: var(--color-light);
`;

const SWhiteButton = styled(WhiteButton)`
  margin-bottom: -30px;
  height: 56px;
  width: 206px;
`;

const Mockup = styled.img`
  width: 1000px;

  @media screen and (min-height: 850px) {
    width: 1200px;
  }
`;
export const Icon = styled.img`
  position: absolute;
  width: 70px;
  height: 70px;
  z-index: -1;
`;
const Meat = styled(Icon)`
  left: calc(0.052vw * -200);
  top: calc(0.052vw * 400);
`;
const Chicken = styled(Icon)`
  left: calc(0.052vw * 0);
  top: calc(0.052vw * 230);
`;

const China = styled(Icon)`
  left: calc(0.052vw * 200);
  top: calc(0.052vw * 300);
`;
const Cake = styled(Icon)`
  right: calc(0.052vw * 200);
  top: calc(0.052vw * 300);
`;
const Gimbap = styled(Icon)`
  right: calc(0.052vw * 0);
  top: calc(0.052vw * 230);
`;
const Japan = styled(Icon)`
  right: calc(0.052vw * -200);
  top: calc(0.052vw * 330);
`;
