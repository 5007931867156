import React from 'react';
import styled from 'styled-components';
import { MobileTitle } from './creator-section-2';
import { WhiteButton } from '../../main/PC/main-section-4';
import mockup from '../../../assets/images/product/mockup-creator-last.png';
import { CREATOR_FORM } from '../../../constants/urls';

export default function CreatorSection4Mobile() {
  return (
    <Wrapper>
      <STitleDefault>
        우리 동네 맛집을 공유하고
        <br />
        <Strong>나만의 맛집 매거진</Strong>
        을 만들어보세요
      </STitleDefault>
      <a href={CREATOR_FORM} target="_blank" rel="noopener noreferrer">
        <SWhiteButton focus={false}>크리에이터 신청하기</SWhiteButton>
      </a>
      <Mockup src={mockup} alt="mockup" />
    </Wrapper>
  );
}

const Wrapper = styled.div`
   background: var(--color-black-text);
   height: calc(0.266vw * 580);
   align-items: center;
   padding-top: calc(0.266vw * 100);
  display: flex;
  flex-direction: column;
  overflow: hidden;

`;
const STitleDefault = styled(MobileTitle)`
  line-height: calc(0.266vw * 30);
  color: var(--color-darker-border);
  margin-bottom: calc(0.266vw * 30);
`;
const Strong = styled.strong` 
  color: var(--color-light);
`;

const SWhiteButton = styled(WhiteButton)`
  margin: 0;
  font-size: calc(0.266vw * 16);
  line-height:calc(0.266vw * 20);
  width: calc(0.266vw * 168);
  height: calc(0.266vw * 40);
  border-radius: calc(0.266vw * 5);
  border: 1.5px solid var(--color-light);
`;
const Mockup = styled.img`
  width: calc(0.266vw * 700);
  margin-top: calc(0.266vw * -20);
`;
