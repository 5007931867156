import React from 'react';
import styled from 'styled-components';
import 'aos/dist/aos.css';
import { useMediaQuery } from 'react-responsive';
import {
  SText, Description, Strong, Strong2,
} from './creator-section-2';
import mockup1 from '../../../assets/images/product/mockup-group1.png';
import mockup2 from '../../../assets/images/product/mockup-group2.png';
import mockup3 from '../../../assets/images/product/mockup-group3.png';
import largerMockup1 from '../../../assets/images/product/mockup-group1-larger.png';
import largerMockup2 from '../../../assets/images/product/mockup-group2-larger.png';
import largerMockup3 from '../../../assets/images/product/mockup-group3-larger.png';

export default function CreatorSection3() {
  const isLargerPC = useMediaQuery({ query: '(min-width: 850px)' });
  return (
    <Wrapper>
      <OutCircle>
        <InCircle />
      </OutCircle>
      <Text>
        내가 공유한 맛집이
        <Strong> 팔로워들의 맛집</Strong>
        이 되는 순간
      </Text>
      <SDescription>
        혼자 먹는 것보다 함께 먹을 때 더 행복하니까
        <br />
        내가 올린 맛집을 사용자들이 방문하면 감사카드가 도착해요.
        <div style={{ height: '15px' }} />
        <Strong2>
          채널에 쌓여가는 감사카드를 확인해 보세요.
        </Strong2>
      </SDescription>
      <SRowFlex>
        {isLargerPC ? <Mockup1 src={largerMockup1} alt="mockup1" /> : <Mockup1 src={mockup1} alt="mockup1" />}
        {isLargerPC ? <Mockup2 src={largerMockup2} alt="mockup2" /> : <Mockup2 src={mockup2} alt="mockup2" />}
        {/* <Mockup2 src={mockup2} alt="mockup2" /> */}

        {isLargerPC ? <Mockup3 src={largerMockup3} alt="mockup3" /> : <Mockup3 src={mockup3} alt="mockup3" />}
      </SRowFlex>
      <CircleContainer>
        <OutCircle2>
          <InCircle2 />
        </OutCircle2>
      </CircleContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--color-gray-input);

  padding-top: 130px;
  @media screen and (min-height: 850px) {
    padding-top: 150px;
  }
  align-items: center;
  overflow: hidden;
`;

const Text = styled(SText)`
  margin-top: 0;
  line-height: 100%;
  position: relative;
  z-index: 999;
`;

const SDescription = styled(Description)`
  position: relative;
  z-index: 999;
`;

const Mockup1 = styled.img`
  width: 270px;
  position: absolute;
  left: 107px;
  margin-top: -50px;
  z-index: 2;
  @media screen and (min-height: 850px) {
    width: 300px;
    left: 195px;
    margin-top: -100px;
  }
`;
const SRowFlex = styled.div`
  position: relative;
  margin-top: -85px;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   z-index: 999;
`;
const Mockup2 = styled.img`
  width: 970px;
  position: relative;
  z-index: 1;
  @media screen and (min-height: 850px) {
    width: 1286px;
  }
`;
const Mockup3 = styled.img`
  width: 270px;
  position: absolute;
  right: 70px;
  z-index: 2;
  @media screen and (min-height: 850px) {
    width: 300px;
    right: 135px;
  }
`;

const OutCircle = styled.div`
  z-index: 0;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(2,0,36);
  background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(243,245,246,1) 100%);
  left: -250px;
  margin-top: 0;
`;
const InCircle = styled.div`
  z-index: 0;
  width: 336px;
  height: 336px;
  position: absolute;
  border-radius: 50%;
  background: var(--color-gray-input);
`;

const CircleContainer = styled.div`
  position: absolute;
  display: inline-block;
  z-index: 1;
  width: 420px;
  height: 420px;
  margin: 0;
  right: -150px;
  bottom: 180px;
`;

const OutCircle2 = styled(OutCircle)`
  position: absolute;
  width: 420px;
  height: 420px;
  left: auto;
  background: rgb(2,0,36);
  background: linear-gradient(0deg, rgba(2,0,36,0) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(243,245,246,1) 100%);
  `;

const InCircle2 = styled(InCircle)`
  width: 235.2px;
  height: 235.2px;
`;
