import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { useMediaQuery } from 'react-responsive';
import { Mobile, PC } from '../../components/layout/mediaQuery';
import { CreatorSection1 } from '../../components/creator/PC/creator-section-1';
import { Header } from '../../components/layout/PC/header';
import { CreatorSection2 } from '../../components/creator/PC/creator-section-2';
import CreatorSection3 from '../../components/creator/PC/creator-section-3';
import { CreatorSection4 } from '../../components/creator/PC/creator-section-4';
import Footer from '../../components/layout/PC/footer';
import { CreatorSection1Mobile } from '../../components/creator/Mobile/creator-section-1';
import { CreatorSection2Mobile } from '../../components/creator/Mobile/creator-section-2';
import CreatorSection3Mobile from '../../components/creator/Mobile/creator-section-3';
import CreatorSection4Mobile from '../../components/creator/Mobile/creator-section-4';
import FooterMobile from '../../components/layout/Mobile/footer';
import { HeaderMobile } from '../../components/layout/Mobile/header';
import '../../pagepiling/jquery.pagepiling';
import '../../pagepiling/jquery.pagepiling.css';
import '../../pagepiling/index.css';
import { CSButton } from '../../components/main/PC/main-section-1';

function Index() {
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [logoWhite, setLogoWhite] = useState(false);
  const [goToTheTop, setGoToTheTop] = useState(false);
  useEffect(() => {
    if (!isMobile) {
      document.body.className = 'pc';
      $(() => {
        ($('#pagepiling') as any).pagepiling({
          afterLoad(_:any, index:number) {
            if (index === 4) {
              setLogoWhite(true);
            } else {
              setLogoWhite(false);
            }
          },
        });
      });
    } else {
      document.body.className = 'mobile';
    }
  }, []);
  useEffect(() => {
    if (goToTheTop) {
     ($ as any).fn.pagepiling.moveTo(1);
    }
  }, [goToTheTop]);

  return (
    <>
      <PC>
        <Header main={false} creatorLastPage={logoWhite} setGoToTheTop={setGoToTheTop} />
        <div id="pagepiling">
          <CSButton />
          <div className="section"><CreatorSection1 /></div>
          <div className="section"><CreatorSection2 /></div>
          <div className="section"><CreatorSection3 /></div>
          <div className="section pp-scrollable">
            <CreatorSection4 />
            <Footer creatorLastPage />
          </div>
        </div>
      </PC>
      <Mobile>
        <HeaderMobile main={false} />
        <CreatorSection1Mobile />
        <CreatorSection2Mobile />
        <CreatorSection3Mobile />
        <CreatorSection4Mobile />
        <FooterMobile creatorLastPage />
      </Mobile>
    </>
  );
}
export default Index;
