import React from 'react';
import styled from 'styled-components';
import { Wrapper } from '../../main/Mobile/main-section-1';
import { MobileTitle, MobileSub } from './creator-section-2';
import { Strong } from '../PC/creator-section-2';
import { Strong2 } from './creator-section-1';
import mockup2 from '../../../assets/images/product/mockup-group2.png';

export default function CreatorSection3Mobile() {
  return (
    <SWrapper>
      <SMobileTitle>
        내가 구독한 맛집이
        <br />
        <Strong>팔로워들의 맛집</Strong>
        이 되는 순간
      </SMobileTitle>
      <MobileSub>
        혼자 먹는 것보다 함께 먹을 때 더 행복하니까
        <br />
        내가 올린 맛집을 사용자들이 방문하면
        <br />
        감사카드가 도착해요.
        <br />
        <Strong2>채널에 쌓여가는 감사카드를 확인해 보세요.</Strong2>
      </MobileSub>
      <Mockup2 src={mockup2} alt="mockup2" />
    </SWrapper>
  );
}
const SWrapper = styled(Wrapper)`
   background: var(--color-skyblue);
   padding-top: calc(0.266vw * 88);
   padding-bottom: calc(0.266vw * 28);
   margin-top: calc(0.266vw * -86);
   
`;
const SMobileTitle = styled(MobileTitle)`
  line-height: calc(0.266vw * 30);
`;

const Mockup2 = styled.img`
  width: calc(0.266vw * 700);
  margin-top: calc(0.266vw * -35);
`;
