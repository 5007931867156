import React from 'react';
import styled from 'styled-components';
import { Wrapper, Title, Sub } from '../../main/Mobile/main-section-1';
import { MobileSub } from './creator-section-2';
import { Bar } from '../PC/creator-section-1';
import mockup from '../../../assets/images/product/mockup-creator-main.png';
import SvgCs from '../../../assets/images/icon/cs';
import { KAKAO_CS_URL } from '../../../constants/urls';

export function CreatorSection1Mobile() {
  return (
    <SWrapper>
      <Title>
        밀리언과 함께 성장할
        <br />
        푸드 크리에이터를 찾습니다
      </Title>
      <SSub>
        *서울, 경기, 인천, 강원도 우선 승인
      </SSub>
      <img src={mockup} alt="phone" width="110%" />
      <SBar />
      <SSubDefault>
        밀리언의 푸드 크리에이터는
        <br />
        사용자와 밀리언을 연결해요.
        <br />
        혼자만 알기 아쉬운 식당과 카페를 공유하고,
        <br />
        사용자는 자신의 취향을 발견해 저장하죠.
        <br />
        <br />
        <Strong2>&apos;나&apos;로 끝나는 게 아닌, &apos;우리&apos;로 이어질 수 있도록</Strong2>
      </SSubDefault>
      <a href={KAKAO_CS_URL} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <Cs>
          <SvgCs background="var(--color-light)" line="var(--color-new-primary)" />
        </Cs>
      </a>
    </SWrapper>
  );
}
const SWrapper = styled(Wrapper)`
  padding-bottom: calc(0.266vw *  75);
`;

const SSubDefault = styled(MobileSub)`
  font-weight: 500;
`;

export const Strong2 = styled.strong`
  font-weight: 700;
`;

const SSub = styled(Sub)`
  line-height: calc(0.266vw * 12);
  font-size: calc(0.266vw * 12);
  color: var(--color-darker-border);
  margin-bottom: calc(0.266vw * -30);
`;

const SBar = styled(Bar)`
  width: calc(0.266vw * 28);
  height: calc(0.266vw * 4);
  margin-top: calc(0.266vw * -20);
  margin-bottom: calc(0.266vw * 19);
`;

const Cs = styled.div`
  position: fixed;
  z-index: 999;
  bottom: calc(0.226vw * 10);
  right: 0;
  width: calc(0.266vw * 85);
  height:  calc(0.266vw * 85);
`;
